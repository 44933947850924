import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '../index'
import { getPayload } from '@/utils/jwt'
import { Role, User } from '../../models/user'

@Module({
  name: 'auth',
  dynamic: true,
  namespaced: true,
  store
})
export default class AuthStoreModule extends VuexModule {
  private _token?: string
  private _user?: User
  private _currentRole?: string | null

  get token (): string | undefined {
    if (!this._token) {
      const tokenStr = localStorage.getItem('token')
      if (tokenStr) {
        this._token = tokenStr
      }
    }
    return this._token
  }

  get isTokenValid (): boolean {
    const tokenStr = localStorage.getItem('token')
    // 为了解决退出不成功的问题,因为removetoken没有删除_token的数据
    if (!tokenStr) {
      return false
    }
    return !!this.user && (this.user.exp * 1000 > Date.now())
  }

  get user (): User | undefined {
    if (!this._user) {
      const userStr = localStorage.getItem('user')
      if (userStr) {
        this._user = JSON.parse(userStr) as User
      }
    }
    return this._user
  }

  get avatar (): string {
    if (this.user && this.user.avatar) {
      return this.user.avatar
    }
    return ''
  }

  get userId (): string {
    if (this.user && this.user.userId) {
      return this.user.userId
    }
    return ''
  }

  get username (): string {
    if (this.user && this.user.username) {
      return this.user.username
    }
    return ''
  }

  get appCode (): string {
    if (this.user && this.user.appCode) {
      return this.user.appCode
    }
    return ''
  }

  get isSuperAdmin (): boolean {
    const role = localStorage.getItem('role')
    if (role === Role.superAdmin) {
      return true
    }
    return false
  }

  get isAdmin (): boolean {
    const role = localStorage.getItem('role')
    if (role === Role.admin) {
      return true
    }
    return false
  }

  get roles (): string[] {
    if (this.user && this.user.roles) {
      console.log('this.user.roles == ', this.user.roles)
      const arr = this.user.roles.split(',')
      return arr
    } else {
      return []
    }
  }

  get currentRole (): string {
    const role = localStorage.getItem('role')
    if (role === Role.teacher) {
      return '正念师'
    } else if (role === Role.admin) {
      return '管理员'
    } else if (role === Role.superAdmin) {
      return '超级管理员'
    }
    return '正念师'
  }

  @Mutation
  setRole (value: string) {
    if (Role.teacher === value) {
      this._currentRole = Role.teacher
    } else if (Role.superAdmin === value) {
      this._currentRole = Role.superAdmin
    } else if (Role.admin === value) {
      this._currentRole = Role.admin
    }
    localStorage.setItem('role', value)
  }

  @Mutation
  setToken (value: string) {
    const payload = getPayload(value)
    this._token = value
    this._user = JSON.parse(payload)
    localStorage.setItem('token', value)
    localStorage.setItem('user', payload)
  }

  @Mutation
  removeToken () {
    localStorage.removeItem('role')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }
}

export const AuthStore = getModule(AuthStoreModule)
