export interface User {
  appCode: string
  avatar: string
  exp: number
  gender: string
  iat: number
  iss: string
  name: string
  phone: string
  sub: string
  userId: string
  username: string
  roles?: string
}

export enum Role {
  teacher = 'MINDUP__teacher',
  admin = 'MINDUP__admin',
  superAdmin = 'MINDUP__superAdmin'
}
