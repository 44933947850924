import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router'

import { AuthStore } from '@/store/modules/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    meta: {
      title: '主页'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Layout.vue'),
    redirect: { name: 'Plans' },
    children: [
      {
        path: 'home',
        name: 'Home',
        meta: {
          title: '首页',
          roles: new Array<string>('MINDUP__teacher', 'MINDUP__admin')
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      }, {
        path: '/users',
        name: 'Users',
        meta: {
          title: '用户管理',
          roles: new Array<string>('MINDUP__superAdmin')
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserList.vue')
      },
      {
        path: 'plans',
        name: 'Plans',
        meta: {
          title: '我的计划',
          roles: new Array<string>('MINDUP__teacher', 'MINDUP__admin')
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Plans.vue'),
        redirect: { name: 'PlanList' },
        children: [
          {
            path: '',
            name: 'PlanList',
            meta: {
              title: '计划列表'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/PlanList.vue')
          },
          {
            path: ':planId',
            name: 'PlanDetail',
            meta: {
              title: '计划详情'
            },
            redirect: { name: 'PlanBasic' },
            component: () => import(/* webpackChunkName: "about" */ '../components/PlanDetail.vue'),
            children: [
              {
                path: 'basic',
                name: 'PlanBasic',
                meta: {
                  title: '基本信息'
                },
                component: () => import(/* webpackChunkName: "about" */ '../components/PlanBasic.vue')
              },
              {
                path: 'management',
                name: 'PlanManagement',
                meta: {
                  title: '计划管理'
                },
                component: () => import(/* webpackChunkName: "about" */ '../components/PlanManagement.vue')
              },
              {
                path: 'surveys',
                name: 'PlanSurvey',
                meta: {
                  title: '问卷管理'
                },
                component: () => import(/* webpackChunkName: "about" */ '../components/PlanSurvey.vue')
              },
              {
                path: 'students',
                name: 'PlanStudent',
                meta: {
                  title: '学员管理'
                },
                component: () => import(/* webpackChunkName: "about" */ '../components/PlanStudent.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'materials',
        name: 'Materials',
        meta: {
          title: '素材库',
          roles: new Array<string>('MINDUP__teacher', 'MINDUP__admin')
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Materials.vue'),
        redirect: { name: 'StandardExerciseMaterial' },
        children: [
          {
            path: 'exercises',
            name: 'StandardExerciseMaterial',
            meta: {
              title: '正式练习素材库'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/StandardExerciseMaterial.vue')
          },
          {
            path: 'practices',
            name: 'LifePracticeMaterial',
            meta: {
              title: '生活练习素材库'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/LifePracticeMaterial.vue')
          },
          {
            path: 'surveys',
            name: 'SurveyMaterial',
            meta: {
              title: '问卷库'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/SurveyMaterial.vue')
          },
          {
            path: 'surveys/:SurveyId',
            name: 'SurveyDetail',
            meta: {
              title: '问卷详情'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/SurveyDetail.vue')
          }
        ]
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: {
          title: '数据看板',
          roles: new Array<string>('MINDUP__teacher', 'MINDUP__admin')
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
        redirect: { name: 'PlanSummary' },
        children: [
          {
            path: 'plans',
            name: 'PlanSummary',
            meta: {
              title: '计划汇总详情'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/PlanSummary.vue')
          },
          {
            path: 'weekly',
            name: 'WeeklySummary',
            meta: {
              title: '周次练习详情'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/WeeklySummary.vue')
          },
          {
            path: 'daily',
            name: 'DailySummary',
            meta: {
              title: '每日计划汇总'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/DailySummary.vue')
          },
          {
            path: 'surveys',
            name: 'SurveySummary',
            meta: {
              title: '问卷统计'
            },
            component: () => import(/* webpackChunkName: "about" */ '../components/SurveySummary.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/unauthorized',
    name: 'UnAuthorized',
    meta: {
      title: '未授权'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/UnAuthorized.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginNew.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const whiteList = ['/login']

const authorizeToken = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  document.title = to.meta.title + ''

  const tokenParam = to.query.token
  if (tokenParam) {
    AuthStore.setToken(tokenParam + '')
    next()
  } else {
    const token = AuthStore.token
    if (token) {
      if (to.path === '/login') {
        next({ path: '/' })
      } else {
        next()
      }
    } else {
      console.log('应该到这里1')
      if (whiteList.indexOf(to.path) !== -1) {
        console.log('应该到这里2')
        next()
      } else {
        next(`/login?redirect=${to.path}`)
      }
    }
  }
}

const checkAuth = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const roles = (to.meta as any)?.roles
  if ((!roles) || roles?.filter((role: string) => AuthStore.user?.roles?.indexOf(role) !== -1).length > 0) {
    next()
  } else {
    next({ name: 'UnAuthorized' })
  }
}

router.beforeEach(authorizeToken)
router.beforeEach(checkAuth)

export default router
